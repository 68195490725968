exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-distributors-js": () => import("./../../../src/pages/distributors.js" /* webpackChunkName: "component---src-pages-distributors-js" */),
  "component---src-pages-electricity-meter-js": () => import("./../../../src/pages/electricity-meter.js" /* webpackChunkName: "component---src-pages-electricity-meter-js" */),
  "component---src-templates-layout-distributor-js": () => import("./../../../src/templates/layoutDistributor.js" /* webpackChunkName: "component---src-templates-layout-distributor-js" */),
  "component---src-templates-layout-distributor-offer-js": () => import("./../../../src/templates/layoutDistributorOffer.js" /* webpackChunkName: "component---src-templates-layout-distributor-offer-js" */),
  "component---src-templates-layout-js": () => import("./../../../src/templates/layout.js" /* webpackChunkName: "component---src-templates-layout-js" */),
  "component---src-templates-sign-offer-js": () => import("./../../../src/templates/signOffer.js" /* webpackChunkName: "component---src-templates-sign-offer-js" */)
}

