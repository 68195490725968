import cssVars from "css-vars-ponyfill"
import ReactDOM from 'react-dom'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
  // Add polyfill for css variables if on ie
  if (window.document.documentMode) {
    cssVars()
  }
}

export const onInitialClientRender = () => {
  const cons = document.getElementById("consumption")
  if (cons != null) {
    cons.onchange = checkConsumption
  }
}

function checkConsumption() {
  const cons = document.getElementById("consumption")
  var constraint = new RegExp("^[1-9][0-9]*$", "")
  if (constraint.test(cons.value)) {
    cons.setCustomValidity("")
  } else {
    cons.setCustomValidity("Попълнете консумацията в кВтч")
  }
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

