import { createTheme } from "@mui/material/styles"

export default createTheme({
  typography: {
    fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
    root: {
      fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#e6401e",
    },
    third: {
      main: "#fff",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
        fontWeight: "700",
        fontSize: "1rem",
        "&.MuiTab-root": {
          color: "rgba(0,0,0,0.8)",
        },
      },
    },
  },
})
